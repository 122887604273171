// Common
html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 62.5% !important;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.75;
  font-family: $font-common;
  background: $color-bg;
  color: $color-base;
  @include fz(15);
  letter-spacing: .08em;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings : "palt";

  @media #{$mq-md} {
    @include fz(16);
  }

  &.is-menu-open {
    position: fixed;
    height: 100vh;
    overflow: hidden;
  }
}
//
// ::selection {
//   background: lighten($color-secondary, 20%);
// }

a {
  text-decoration: none;
  color: $color-link;
  outline: none;

  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

ul,
li,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

svg,
img {
  max-width: 100%;
  outline: none;
}

figure {
  margin: 0;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

// -----------
// Smartphone
// -----------
#mediaFlg {
  display: none;

  @media #{$mq-not-sm} {
    font-family: 'sp';
  }

  @media #{$mq-sm} {
    font-family: 'tab';
  }

  @media #{$mq-md} {
    font-family: 'pc';
  }
}
