.p-contact-info {
  margin-top: 30px;

  @media #{$mq-sm} {
    margin-top: 80px;
  }

  &__desc {
    @extend %center-md;
    @extend %gutter;
  }
}

.p-contact-tel {
  @extend %center-sm;
  @extend %gutter;
  margin-top: 30px;
  text-align: center;

  @media #{$mq-sm} {
    margin-top: 56px;
  }

  &__inner {
    padding: 18px 15px 20px;
    border: solid 5px $color-border;
  }

  &__title {
    line-height: 1.5;
    @include fz(16);
    letter-spacing: .16em;

    @media #{$mq-sm} {
      @include fz(20);
    }
  }

  &__num {
    display: inline-block;
    margin: 20px auto 0;
    padding: 0 36px;
    background: url(../images/icon_phone.svg) left center/24px auto no-repeat;

    @media #{$mq-sm} {
      padding: 0 44px;
      background-size: 32px auto;
    }

    img {
      display: block;
    }
  }

  &__note {
    margin-top: .5em;
    @include fz(12);

    @media #{$mq-sm} {
      @include fz(14);
    }
  }
}

.p-contact-entry {
  @extend %center-md;
  @extend %gutter;
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 80px;
  }

  &__title {
    line-height: 1.5;
    text-align: center;
    @include fz(20);
    letter-spacing: .16em;

    @media #{$mq-sm} {
    }
  }

  &__form {
    margin-top: 30px;

    @media #{$mq-sm} {
      margin-top: 60px;
    }
  }
}

.p-contact-form {

  &__section {

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}
