.l-header {
  position: relative;
  z-index: 1000;
  width: 100vw;
  height: $header-h-sp;
  background: #fff;

  @media #{$mq-md} {
    height: $header-h-pc;
  }

  #body.is-menu-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    animation: sweepFromTop $duration ease-out;
    // box-shadow: 0 4px 4px rgba(0, 0, 0, .04);

    @media #{$mq-md} {
      height: $header-h-pc-fixed;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: $gutter-sp;

    @media #{$mq-md} {
      align-items: stretch;
      padding-left: $gutter-outside-pc;
      padding-right: $gutter-outside-pc;
    }
  }

  &__menu {
    opacity: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: calc(100vh - 64px);
    margin-top: $header-h-sp;
    background: transparentize($color-bg, .2);
    color: #fff;

    @media #{$mq-md} {
      opacity: 1;
      overflow-y: visible;
      position: static;
      display: block;
      width: auto;
      height: 100%;
      margin-top: 0;
      background: none;
    }

    #body.is-menu-open & {
      opacity: 1;
      display: block;
      animation: fadeIn $duration ease forwards;

      @media #{$mq-md} {
        animation: none;
      }
    }
  }
}
