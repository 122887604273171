.l-container {

}

.l-contents {
  transition: filter $duration;

  #body.is-menu-open & {
    filter: blur(4px);

    @media #{$mq-md} {

    }
  }

  #body.is-menu-fixed & {
    padding-top: $header-h-sp;

    @media #{$mq-md} {
      padding-top: $header-h-pc-fixed;
    }
  }
}

.l-main {

}

.l-section {

  + .l-section {
    margin-top: 48px;

    @media #{$mq-sm} {
      margin-top: 80px;
    }
  }

  &__inner {
    @extend %gutter;
    @extend %center-xxl;
  }

  &__header {

    @media #{$mq-md} {
      flex: 1;
    }
  }

  &__contents {
    margin-top: 20px;

    @media #{$mq-md} {
      width: 70%;
      margin-top: 0;
    }
  }
}
