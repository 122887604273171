@mixin cf() {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
}

@mixin fz($size: 14) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}

@mixin hover-opacity($opacity: .8) {
  transition: opacity $duration;

  &:hover {
    opacity: $opacity;
    text-decoration: none;
  }
}

@mixin hover-opacity-img($opacity: .8) {

  img {
    transition: opacity $duration;
  }

  &:hover {

    img {
      opacity: $opacity;
      text-decoration: none;
    }
  }
}

@mixin youtube-wrap() {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@mixin map-wrap() {
  position: relative;
  // padding-bottom: 75%; // これが縦横比
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

/*
 * Mixin for placeholder
 * @include placeholderColor(#00ff00);
 */
@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color:$color;
  }
  &:-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color:$color;
  }
}
