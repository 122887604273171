.p-header-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0;

  @media #{$mq-md} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  &__item {
    @include hover-opacity;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 16px $gutter-sp;

    @media #{$mq-md} {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      padding: 0;
      text-align: center;
      color: $color-base;
    }

    &:not(:first-child) {

      @media #{$mq-md} {
        margin-left: 3em;
      }
    }

    .en {
      @extend %font-strong;
      @include fz(11);
      color: $color-primary-light;
    }

    .ja {
      margin-right: 1em;
      color: $color-base;
      @include fz(15);
      font-weight: 700;

      @media #{$mq-md} {
        margin-top: .375em;
        margin-right: 0;
      }
    }
  }
}

.p-toggle-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $header-h-sp;
  height: $header-h-sp;

  @media #{$mq-md} {
    display: none;
  }

  &__btn {
    $h: 2px;

    position: relative;
    display: block;
    width: 11px;
    margin-left: 5px;
    height: $h;
    background: $color-base;
    transition: all $duration;

    #body.is-menu-open & {
      width: 23px;
      background: transparent;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      display: block;
      height: $h;
      background: $color-base;
      transition: all $duration ease;
    }

    &::before {
      top: -6px;
      width: 16px;

      #body.is-menu-open & {
        top: 0;
        width: 23px;
        transform: rotate(45deg);
      }
    }

    &::after {
      top: 6px;
      width: 14px;

      #body.is-menu-open & {
        top: 0;
        width: 23px;
        transform: rotate(-45deg);
      }
    }
  }
}
