.c-section-title {

  &__en {
    display: block;
    transform: scale(.8);
    transform-origin: left top;

    @media #{$mq-sm} {
      transform: scale(1);
    }
  }

  &__ja {
    margin-top: .25em;
    @include fz(16);
    color: $color-primary;

    @media #{$mq-sm} {
      margin-top: .75em;
      @include fz(18);
    }

    @media #{$mq-xl} {
      @include fz(20);
    }
  }
}

.c-section-headline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 72px;
  margin: 30px auto 20px;
  text-align: center;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  @include fz(18);
  letter-spacing: .16em;

  @media #{$mq-sm} {
    margin-top: 60px;
    margin-bottom: 48px;
    @include fz(24);
  }

  &.c-section-headline--services {
    min-height: 72px;
    background-image: url(../images/text_script_services.svg);
  }

  &.c-section-headline--interview {
    min-height: 75px;
    background-image: url(../images/text_script_interview.svg);
  }

  img {
    position: relative;
    z-index: 1;
    display: block;
  }
}

.c-section-subhead {
  line-height: 1.5;
  @include fz(18);
  color: $color-base-light;
  letter-spacing: .16em;

  @media #{$mq-sm} {
    @include fz(24);
  }

  &.c-section-subhead--center {
    text-align: center;
  }
}

.c-section-desc {
  margin-top: 1.5em;

  @media #{$mq-sm} {
    text-align: center;
  }
}

.c-data-list {

  &__item {

    @media #{$mq-sm} {
      display: flex;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media #{$mq-sm} {
        margin-top: 30px;
      }
    }
  }

  &__title {
    min-width: 100px;
    margin-bottom: .5em;
    font-weight: 700;

    @media #{$mq-md} {
      margin-bottom: 0;
    }

    &.c-data-list__title-large {
      min-width: 136px;
    }
  }

  &__data {
    flex: 1;
    @include fz(14);

    @media #{$mq-sm} {
      @include fz(15);
    }
  }

  &__headline {
    display: block;
    margin-bottom: .5em;
    font-size: 1em;

    @media #{$mq-md} {
      margin-bottom: 1em;
    }

    &:not(:first-child) {
      margin-top: 2em;
    }
  }
}

.c-interview-headline {
  position: relative;
  line-height: 1.5;
  margin-bottom: 2em;
  padding-left: 40px;
  @include fz(16);

  @media #{$mq-sm} {
    padding-left: 70px;
    @include fz(18);
  }

  &::before {
    content: '';
    position: absolute;
    top: .75em;
    left: 0;
    display: inline-block;
    width: 30px;
    height: 1px;
    background: $color-base;

    @media #{$mq-sm} {
      width: 50px;
    }
  }

  &:not(:first-child) {
    margin-top: 4em;
  }
}

.c-interview-body {

  p {

    + p {
      margin-top: 1em;
    }
  }
}

.c-interview-figure {
  margin: 30px auto;

  @media #{$mq-sm} {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  img {
    display: block;
    width: 100%;
  }
}
