// -----------------------------------------------------------------
// Layout
// -----------------------------------------------------------------
%gutter {
  padding-left: $gutter-sp;
  padding-right: $gutter-sp;

  @media #{$mq-sm} {
    padding-left: $gutter-outside-pc;
    padding-right: $gutter-outside-pc;
  }
}

%center-sm {
  width: 100%;
  max-width: $content-width-sm;
  margin-left: auto;
  margin-right: auto;
}

%center-md {
  width: 100%;
  max-width: $content-width-md;
  margin-left: auto;
  margin-right: auto;
}

%center-lg {
  width: 100%;
  max-width: $content-width-lg;
  margin-left: auto;
  margin-right: auto;
}

%center-xl {
  width: 100%;
  max-width: $content-width-xl;
  margin-left: auto;
  margin-right: auto;
}

%center-xxl {
  width: 100%;
  max-width: $content-width-xxl;
  margin-left: auto;
  margin-right: auto;
}

// -----------------------------------------------------------------
// Fonts
// ----------------------------------------------------------------
%font-strong {
  font-family: $font-strong;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .08em;
}
