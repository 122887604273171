@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes flipInLeft {
  0% {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes scrollBtn {
  0% {
    transform: scale(0, 1);
    transform-origin: left center;
  }

  49% {
    transform-origin: left center;
  }

  50% {
    transform: scale(1, 1);
    transform-origin: right center;
  }

  100% {
    transform: scale(0, 1);
    transform-origin: right center;
  }
}

@keyframes sweepFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes flipOutTop {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.js-scroll-view {

  &.is-flip-in-bottom {
    opacity: 0;
    transform: translateY(20px);
    transition: all $duration-slow .2s $easeOutCubic;
    will-change: transform;

    &.is-animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.is-01 {

    @media #{$mq-sm} {
      transition-delay: $duration;
    }
  }

  &.is-02 {

    @media #{$mq-sm} {
      transition-delay: $duration * 2;
    }
  }

  &.is-03 {

    @media #{$mq-sm} {
      transition-delay: $duration * 3;
    }
  }

  &.is-04 {

    @media #{$mq-sm} {
      transition-delay: $duration * 4;
    }
  }

  &.is-05 {

    @media #{$mq-sm} {
      transition-delay: $duration * 5;
    }
  }

  &.is-06 {

    @media #{$mq-sm} {
      transition-delay: $duration * 6;
    }
  }

  &.is-07 {

    @media #{$mq-sm} {
      transition-delay: $duration * 7;
    }
  }

  &.is-08 {

    @media #{$mq-sm} {
      transition-delay: $duration * 8;
    }
  }

  &.is-09 {

    @media #{$mq-sm} {
      transition-delay: $duration * 9;
    }
  }

  &.is-10 {

    @media #{$mq-sm} {
      transition-delay: $duration * 10;
    }
  }

  &.is-11 {

    @media #{$mq-sm} {
      transition-delay: $duration * 11;
    }
  }
}
