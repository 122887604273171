.p-works {
  @extend %center-xxl;
  @extend %gutter;
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 80px;
  }

  &__title {

  }

  &__list {
    margin-top: 20px;

    @media #{$mq-sm} {
      margin-top: 52px;
    }
  }
}

.p-works-list {

  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {

    @media #{$mq-sm} {
      width: calc(50% - 40px);
    }

    @media #{$mq-md} {
      width: calc((100% - 80px) / 3);
    }

    &:not(:first-child) {
      margin-top: 30px;

      @media #{$mq-sm} {
        margin-top: 0;
      }
    }

    &:nth-child(n + 3) {

      @media #{$mq-sm} {
        margin-top: 54px;
      }

      @media #{$mq-md} {
        margin-top: 0;
      }
    }

    &:nth-child(n + 4) {

      @media #{$mq-md} {
        margin-top: 54px;
      }
    }
  }
}

.p-work-item {

  &__image {

    img {
      display: block;
      width: 100%;
    }
  }

  &__title {
    line-height: 1.5;
    margin-top: 1em;
    text-align: center;
    @include fz(16);

    @media #{$mq-sm} {
      @include fz(18);
    }
  }

  &__body {
    margin-top: 1em;
    @include fz(14);
  }
}
