.p-message-signature {
  margin-top: 2.5em;
  text-align: right;
  @include fz(13);
  font-weight: 700;

  @media #{$mq-sm} {
    @include fz(14);
  }

  &__company {
  }

  &__name {
    margin-top: .75em;
    @include fz(12);

    .position {
      margin-right: .5em;
    }

    .name {
      font-size: 1.5em;
      letter-spacing: .16em;
    }
  }
}

.p-company {
  @extend %center-lg;
  @extend %gutter;
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 100px;
  }

  &__title {

  }
}

.p-company-profile {
  margin-top: 20px;

  @media #{$mq-sm} {
    margin-top: 76px;
  }

  @media #{$mq-md} {
    display: flex;
    justify-content: space-between;
  }

  &__col {

    @media #{$mq-md} {
      width: calc(50% - 20px);
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media #{$mq-sm} {
        margin-top: 30px;
      }

      @media #{$mq-md} {
        margin-top: 0;
      }
    }
  }
}

.p-history {
  @extend %center-lg;
  @extend %gutter;
  margin-top: 60px;

  @media #{$mq-sm} {
    display: flex;
    margin-top: 116px;
  }

  &__title {

    @media #{$mq-sm} {
      width: 160px;
      margin-right: $gutter-pc;
    }
  }

  &__timeline {
    margin-top: 20px;

    @media #{$mq-sm} {
      flex: 1;
      margin-top: 0;
    }
  }
}

.p-history-timeline {
  position: relative;
  margin-bottom: 20px;
  padding-left: 24px;
  @include fz(16);

  @media #{$mq-sm} {
    margin-bottom: 48px;
    padding-left: 60px;
    @include fz(18);
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(.875em - 5px);
    left: 0;
    display: block;
    width: 10px;
    height: calc(100% + 12px);
    border-radius: 5px;
    background: $color-primary-xxlight;

    @media #{$mq-sm} {
      height: calc(100% + 36px);
    }
  }

  &__item {
    position: relative;
    display: flex;

    @media #{$mq-sm} {
      align-items: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(.875em - 6px);
      left: -25px;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $color-primary;

      @media #{$mq-sm} {
        left: -61px;
      }
    }

    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  &__year {
    min-width: 3em;
    @extend %font-strong;

    @media #{$mq-sm} {
      min-width: 4.5em;
    }
  }

  &__body {

  }
}
