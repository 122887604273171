.c-intro {
  @extend %center-xxl;

  &__inner {
    width: 95%;
    padding: 48px $gutter-sp;
    background: $color-primary-xlight;

    @media #{$mq-md} {
      width: 79.6875%;
      padding: 76px 60px;
    }
  }
}

.c-intro-title {
  position: relative;
  padding-top: 40px;

  @media #{$mq-sm} {
    padding-top: 60px;
  }

  @media #{$mq-md} {
    padding-top: 78px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: -($gutter-sp);
    display: block;
    width: calc(100% + 40px);
    height: 160%;
    background-position: $gutter-sp / 2 0;
    background-repeat: no-repeat;
    background-size: 84% auto;

    @media #{$mq-sm} {
      left: 0;
      background-position: 0 0;
    }
  }

  &.c-intro-title--technology {
    margin-top: -16px;

    @media #{$mq-sm} {
      margin-top: -24px;
    }

    @media #{$mq-md} {
      margin-top: -32px;
    }

    &::before {
      background-image: url(../images/text_script_our-technology.svg);

      @media #{$mq-sm} {
        background-size: 534px auto;
      }

      @media #{$mq-md} {
        background-size: 712px auto;
      }
    }
  }

  &.c-intro-title--top-message {

    &::before {
      background-image: url(../images/text_script_top-message.svg);

      @media #{$mq-sm} {
        margin-left: -28px;
        background-size: 423px auto;
      }

      @media #{$mq-md} {
        background-size: 564px auto;
      }
    }
  }

  &.c-intro-title--works {

    &::before {
      background-image: url(../images/text_script_good-workmanship.svg);

      @media #{$mq-sm} {
        margin-left: -12px;
        background-size: 445px auto;
      }

      @media #{$mq-md} {
        background-size: 890px auto;
      }
    }
  }

  img {
    position: relative;
    z-index: 1;
    display: block;
  }
}

.c-intro-body {
  line-height: 2;
  margin-top: 2.75em;

  @media #{$mq-sm} {
    @include fz(18);
  }

  p {

    + p {
      margin-top: 1em;
    }
  }
}
