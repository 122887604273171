.c-loader {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  pointer-events: none;

  [data-load="true"] & {
    animation: fadeOut $duration-middle 1 $duration forwards;
  }

  &__bg {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background: #fff;
  }

  &__logo {
    position: fixed;
    z-index: 10001;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);

    [data-load="true"] & {
      animation: fadeOut $duration-middle 1 forwards;
    }

    .c-loader-logo {
      display: block;
      width: 200px;

      @media #{$mq-sm} {
        width: 240px;
      }
    }
  }

  &__bar {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    height: 5px;
    width: 100vw;
    background-color: $color-base;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform .2s;
    animation: flipInLeft 5s linear;
    will-change: transform;

    [data-load="true"] & {
      transform: scaleX(1) !important;
      animation: fadeOut $duration-middle 1 .1s forwards;
    }
  }
}
