.p-top-technology {
  padding: 48px 0;
  background: $color-primary-xlight url(../images/bg_rail.png) right top/contain no-repeat;

  @media #{$mq-sm} {
    padding: 80px 0;
  }

  &__desc {
    margin-top: 2em;
    @include fz(16);
    font-weight: 400;

    @media #{$mq-sm} {
      @include fz(18);
    }
  }
}

.p-top-works {

  @media #{$mq-sm} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__figure {
    display: grid;
    grid-template-rows: calc((100vw - 120px) / 2 - 20px) calc((100vw - 120px) / 2 - 20px);
    grid-template-columns: calc((100vw - 120px) / 2 - 20px) calc((100vw - 120px) / 2 - 20px);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    padding: 20px 40px;

    @media #{$mq-sm} {
      grid-template-rows: 160px 160px;
      grid-template-columns: 160px 160px;
      grid-row-gap: 60px;
      grid-column-gap: 60px;
      width: calc(50% - 20px);
      padding: 0 90px;
    }
  }

  &__image {

    @media #{$mq-sm} {

    }

    &.p-top-works__image--01 {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    &.p-top-works__image--02 {
      grid-row: 2 / 2;
      grid-column: 1 / 2;
    }

    &.p-top-works__image--03 {
      grid-row: 1 / 2;
      grid-column: 2 / 2;
    }

    &.p-top-works__image--04 {
      grid-row: 2 / 2;
      grid-column: 2 / 2;
    }
  }

  &__text {
    margin-top: 24px;

    @media #{$mq-sm} {
      width: calc(50% - 20px);
      margin-top: 0;
    }
  }
}

.p-top-about {
  padding-left: 0;
  padding-right: 0;

  @media #{$mq-sm} {
    display: flex;
    flex-direction: row-reverse;
    padding-left: $gutter-outside-pc;
    padding-right: $gutter-outside-pc;
  }

  &__image {

    @media #{$mq-sm} {
      width: 50%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    background: $color-base;
    color: #fff;
    padding: 30px 20px;

    @media #{$mq-sm} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      padding: 50px 48px 70px;
    }
  }
}

.p-top-recruit {

  @media #{$mq-sm} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__image {

    @media #{$mq-sm} {
      width: calc(50% - 20px);
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    margin-top: 24px;

    @media #{$mq-sm} {
      width: calc(50% - 20px);
      margin-top: 0;
    }
  }
}
