.l-footer {
  margin-top: 80px;
  padding: 40px 0;
  background: $color-bg-alt-2;

  @media #{$mq-sm} {
    margin-top: 120px;
    padding: 80px 0;
  }

  &__inner {
    @extend %gutter;
    @extend %center-xxl;

    @media #{$mq-md} {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
