.p-services {
  @extend %gutter;
}

.p-services-list {

  @media #{$mq-sm} {
    display: flex;
    justify-content: center;
  }

  &__item {

    @media #{$mq-sm} {
      width: calc(50% - 20px);
      max-width: 460px;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media #{$mq-sm} {
        margin-top: 0;
        margin-left: 40px;
      }
    }
  }
}

.p-service-item {
  padding: 32px 24px;
  border: solid 5px $color-border;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;

    img {
      display: block;
    }
  }

  &__title {
    line-height: 1.5;
    margin-top: 1em;
    text-align: center;
    @include fz(16);

    @media #{$mq-sm} {
      @include fz(18);
    }
  }

  &__body {
    margin-top: 1.5em;
  }
}

.p-technology {
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 80px;
  }

  &__section {

  }
}
