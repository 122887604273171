.p-footer-profile {

  &__name {
    @include fz(12);
    font-weight: 700;
    letter-spacing: .16em;

    .name {
      margin-left: .25em;
      font-size: 1.2em;
    }
  }

  &__address {
    margin-top: 1em;
    @include fz(12);

    @media #{$mq-sm} {
      margin-top: 2em;
      @include fz(14);
    }
  }
}

.p-footer-menu {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media #{$mq-md} {
    flex-wrap: nowrap;
    margin-top: 0;
  }

  &__item {
    @include hover-opacity;
    width: 50%;
    margin-top: 1.5em;
    @include fz(13);
    font-weight: 700;
    color: $color-base;

    @media #{$mq-md} {
      width: auto;
      margin-top: 0;
      @include fz(14);
    }

    &:not(:first-child) {

      @media #{$mq-md} {
        margin-left: 3.5em;
      }
    }
  }
}

.p-footer-copyright {
  @extend %font-strong;
  display: block;
  margin-top: 40px;
  @include fz(12);
  letter-spacing: .16em;

  @media #{$mq-md} {
    position: absolute;
    right: $gutter-outside-pc;
    bottom: 0;
    margin-top: 0;
    text-align: right;
  }

  .year {
    font-size: 1.16em;
  }
}
