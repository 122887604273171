.p-kv {
  overflow: hidden;
  position: relative;

  &__image {
    overflow: hidden;
    width: 200%;
    margin-left: -50%;

    @media #{$mq-sm} {
      width: 100%;
      margin-left: 0;
    }

    img {
      display: block;
      width: 100%;
      transform: scale(1.05);
      transition: transform $duration-slow .3s $easeOutQuint;
      will-change: transform;

      [data-load="true"] & {
        transform: scale(1);
      }
    }
  }

  &__text {
    @extend %gutter;
    position: absolute;
    left: 0;
    bottom: 8%;
    width: 100%;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .4));

    @media #{$mq-sm} {
      bottom: 20%;
      max-width: 2400px;
    }

    .en {
      width: 80.36%;
      margin-left: -1px;
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);

      @media #{$mq-sm} {
        width: 40.18%;
      }

      [data-load="true"] & {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        transition: clip-path $duration-slow .8s $easeOutQuint;
      }
    }

    .ja {
      width: 83.36%;
      margin-top: 15px;
      margin-left: -3px;
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);

      @media #{$mq-sm} {
        width: 41.68%;
        margin-top: 2.3%;
      }

      [data-load="true"] & {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        transition: clip-path $duration-slow 1s $easeOutQuint;
      }
    }

    img {
      display: block;
    }
  }
}

.p-kv-slider {
  line-height: 0;

  &__item {

    img {
      display: block;
      width: 100%;
    }
  }
}
