.c-page-header {
  position: relative;
  padding: 24px 0 20px;
  background: $color-bg-alt;

  @media #{$mq-sm} {
    padding: 96px 0 80px;
  }

  &.c-page-header--no-bg {

    @media #{$mq-sm} {
      background-color: transparent;
    }
  }

  &__image {
    line-height: 0;
    margin-top: 20px;
    margin-bottom: -40px;
    text-align: right;

    @media #{$mq-sm} {
      position: absolute;
      right: 0;
      bottom: -50px;
      width: 64.0625%;
      margin-top: 0;
      margin-right: -($gutter-outside-pc);
      margin-bottom: 0;
    }

    img {
      width: calc(100% - 20px);

      @media #{$mq-sm} {
        width: 100%;
      }
    }
  }
}

.c-page-title {
  @extend %gutter;
  @extend %center-xxl;
  @include fz(18);

  @media #{$mq-sm} {
    @include fz(24);
  }

  &__en {
    display: block;
    margin-bottom: .3125em;
    transform: scale(.6);
    transform-origin: left center;

    @media #{$mq-sm} {
      margin-bottom: .875em;
      transform: scale(1);
    }

    &.c-page-title__en--technology {
      margin-bottom: .0;

      @media #{$mq-sm} {
        margin-bottom: .25em;
      }
    }

    img {
      display: block;
    }
  }

  &__ja {
    line-height: 1.5;
    color: $color-primary;
    letter-spacing: .16em;

    @media #{$mq-sm} {
      letter-spacing: .24em;
    }
  }
}
