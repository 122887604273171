.c-top-lead {
  margin-top: 1.25em;
  font-size: 1em;

  @media #{$mq-xl} {
    font-size: 1.2em;
  }
}

.c-top-desc {
  margin-top: 1.25em;
  @include fz(13);

  @media #{$mq-sm} {
    @include fz(14);
  }

  @media #{$mq-xl} {
    @include fz(15);
  }
}

.c-technology-article {
  @extend %gutter;
  @extend %center-xxl;

  @media #{$mq-md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:nth-child(odd) {

    .c-technology-article__text {

      @media #{$mq-md} {
        margin-right: $gutter-pc;
      }
    }

    .c-technology-article__image {

      @media #{$mq-md} {
        margin-right: -($gutter-outside-pc);
      }
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .c-technology-article__text {

      @media #{$mq-md} {
        margin-left: $gutter-pc;
      }
    }

    .c-technology-article__image {

      @media #{$mq-md} {
        margin-left: -($gutter-outside-pc);
      }
    }
  }

  + .c-technology-article {
    margin-top: 40px;

    @media #{$mq-sm} {
      margin-top: 80px;
    }
  }

  &__text {

    @media #{$mq-md} {
      width: $col-5;
    }
  }

  &__image {
    margin-top: 30px;

    @media #{$mq-md} {
      flex: 1;
      margin-top: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__headline {
    line-height: 1.5;
    padding-bottom: 1.25em;
    @include fz(20);
    color: $color-primary;
    letter-spacing: .16em;

    @media #{$mq-sm} {
      @include fz(32);
    }
  }

  &__body {

    p {

      + p {
        margin-top: 1em;
      }
    }
  }
}
