.c-form-item {

  @media #{$mq-sm} {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    position: relative;
    min-width: 160px;
    line-height: 1.5;
    padding-bottom: 12px;
    @include fz(15);

    @media #{$mq-sm} {
      margin-right: 40px;
      padding-top: 1.6rem;
      padding-bottom: 0;
      @include fz(16);
    }

    &.is-required {

      &::after {
        content: '必須';
        position: relative;
        top: -1px;
        display: inline-block;
        line-height: 1.5;
        margin-left: 1em;
        padding: .25em 1em;
        text-align: center;
        border-radius: 1em;
        background: $color-caution;
        @include fz(11);
        color: #fff;
        letter-spacing: .16em;
        text-indent: .16em;
        white-space: nowrap;

        @media #{$mq-md} {
          position: absolute;
          top: 2.8rem;
          left: -66px;
          margin-left: 0;
          transform: translateY(-50%);
        }
      }
    }

    .title {
      display: inline-block;
      margin-right: .5em;
    }
  }

  &__body {
    flex: 1;

    .caution {
      margin-top: .25em;

      @media #{$mq-sm} {
        margin-top: .75em;
      }
    }
  }

  label {
    cursor: pointer;
  }
}

.c-form-radio {

  @media #{$mq-sm} {
    padding-top: 1.6rem;
  }

  &__item {
    display: block;
    @include fz(15);
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 18px;
    }
  }

  label {
    display: block;
  }

  input[type="radio"] {
    display: none;

    &:checked {

      + .label-item {

        &::before {
          border-color: $color-base;
        }

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    + .label-item {
      position: relative;
      padding-left: 40px;
      transition: all $duration;

      &::before {
        z-index: 0;
        top: 50%;
        left: 0;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        border: solid 2px $color-base-xlight;
        border-radius: 50%;
        background: #fff;
      }

      &::after {
        opacity: 0;
        z-index: 1;
        top: 50%;
        left: 7px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        border-radius: 50%;
        background: $color-base;
        transform: scale(0);
      }

      &::before,
      &::after {
        display: block;
        position: absolute;
        content: '';
        transition: all $duration;
      }
    }
  }

  .label-item {
    display: block;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
select,
textarea {
  width: 100%;
  padding: 1em;
  border: solid 2px $color-base-xlight;
  line-height: 1.5;
  border-radius: 0;
  color: $color-base;
  font-size: 1.6rem;
  box-shadow: none;
  appearance: none;
  letter-spacing: .08em;
  transition: all $duration;

  @media #{$mq-sm} {
    margin-bottom: 0;
  }

  &:focus {
    border-color: $color-base;
  }

  &::placeholder {
    color: transparentize($color-base, .6);
    letter-spacing: .08em;

    @media #{$mq-sm} {
      letter-spacing: .16em;
    }
  }
}

textarea {
  min-height: 10.5em;
}
