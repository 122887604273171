.p-recruit-intro {
  @extend %center-md;
  @extend %gutter;
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 80px;
  }

  &__catch {

    img {
      display: block;
    }
  }

  &__body {
    margin-top: 30px;

    @media #{$mq-sm} {
      margin-top: 54px;
    }

    p {

      + p {
        margin-top: 1em;
      }
    }
  }
}

.p-recruit-profile {
  @extend %center-lg;
  @extend %gutter;
  margin-top: 60px;

  @media #{$mq-sm} {
    margin-top: 120px;
  }

  &__job {

    @media #{$mq-md} {
      display: flex;
    }

    &:not(:first-child) {
      margin-top: 54px;

      @media #{$mq-sm} {
        margin-top: 108px;
      }
    }
  }

  &__title {
    line-height: 1.5;
    @include fz(18);

    @media #{$mq-sm} {
      @include fz(22);
    }

    @media #{$mq-md} {
      width: 37.5%;
      margin-right: 40px;
      padding-left: 100px;
    }
  }

  &__data {

    @media #{$mq-md} {
      flex: 1;
    }
  }
}

.p-recruit-interview {
  @extend %center-xl;
  @extend %gutter;
  margin-top: 54px;

  @media #{$mq-sm} {
    margin-top: 108px;
  }
}

.p-interview-list {

  @media #{$mq-sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {

    @media #{$mq-sm} {
      width: calc(50% - 40px);
    }

    @media #{$mq-md} {
      width: calc((100% - 80px) / 3);
    }

    &:not(:first-child) {
      margin-top: 30px;

      @media #{$mq-sm} {
        margin-top: 0;
      }
    }

    &:nth-child(n + 3) {

      @media #{$mq-sm} {
        margin-top: 54px;
      }

      @media #{$mq-md} {
        margin-top: 0;
      }
    }

    &:nth-child(n + 4) {

      @media #{$mq-md} {
        margin-top: 54px;
      }
    }
  }
}

.p-interview-list-item {
  padding: 32px 24px;
  border: solid 5px $color-border;

  &__image {
    overflow: hidden;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;

    a {
      @include hover-opacity;
      display: block;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__name {
    @include hover-opacity;
    line-height: 1.5;
    margin-top: 1em;
    text-align: center;
    @include fz(16);
    font-weight: 700;
    // letter-spacing: .16em;

    @media #{$mq-sm} {

    }

    a {
      color: inherit;
    }
  }

  &__outline {
    line-height: 1.5;
    margin-top: .75em;
    text-align: center;
    @include fz(12);
    font-weight: 700;
    color: $color-primary;

    @media #{$mq-sm} {
      @include fz(13);
    }
  }

  &__catch {
    margin-top: 1.5em;
    text-align: center;
    font-size: 1em;
  }

  &__more {
    margin-top: 1.75em;
    text-align: center;
  }
}

.p-interview-header {
  @extend %center-md;
  @extend %gutter;
  padding-top: 40px;
  padding-bottom: 40px;

  @media #{$mq-sm} {
    display: flex;
    align-items: center;
  }

  &__image {
    overflow: hidden;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;

    @media #{$mq-sm} {
      width: 160px;
      height: 160px;
      margin: 0 40px 0 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    margin-top: 16px;
    text-align: center;

    @media #{$mq-sm} {
      flex: 1;
      margin-top: 0;
      text-align: left;
    }
  }

  &__name {
    line-height: 1.5;
    @include fz(18);
    font-weight: 700;

    @media #{$mq-sm} {
      @include fz(20);
      font-weight: 700;
      letter-spacing: .16em;
    }
  }

  &__outline {
    line-height: 1.5;
    margin-top: .5em;
    font-weight: 700;
    color: $color-primary;

    @media #{$mq-sm} {
    }
  }
}

.p-interview-contents {
  @extend %center-md;
  @extend %gutter;
}

.p-interview-more {
  @extend %center-xl;
  @extend %gutter;
  margin-top: 40px;

  @media #{$mq-sm} {
    margin-top: 100px;
  }
}
