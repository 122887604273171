.p-header-logo {
  display: flex;
  align-items: center;
  height: 100%;

  @media #{$mq-md} {

  }

  a {
    @include hover-opacity;
    display: block;
  }

  img {
    display: block;
    width: 160px;

    @media #{$mq-md} {
      width: 208px;
    }

    #body.is-menu-fixed & {

      @media #{$mq-md} {
        width: 160px;
      }
    }
  }
}
