.c-button-area {
  margin-top: 40px;
  text-align: center;

  @media #{$mq-sm} {
    margin-top: 60px;
  }
}

.c-button {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 260px;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25em 1em;
  border: none;
  text-align: center;
  background: $color-button;
  font-weight: 700;
  color: #fff;
  transition: background-color $duration, border-color $duration;
  letter-spacing: .16em;
  box-shadow: none;
  appearance: none;
  cursor: pointer;

  @media #{$mq-sm} {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    background-color: $color-button-hover;
  }

  &:not(:first-child) {
    margin-top: 30px;

    @media #{$mq-sm} {
      margin-top: 40px;
    }
  }

  &:hover {

  }

  &:visited {

  }

  &.c-button--small {
    max-width: 200px;
    height: 48px;
    padding: 1em;
    @include fz(14);
  }

  &.c-button--primary {
    background-color: $color-button-primary;

    &:hover {
      background-color: $color-button-primary-hover;
    }
  }

  &.c-button--scroll {
    border: solid 5px $color-border;
    background-color: #fff;
    color: $color-base;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 27px;
      width: 2px;
      height: 9px;
      margin-top: -7px;
      border-radius: 1px;
      background: $color-base;
      transition: margin-top $duration;
    }

    &::before {
      transform: rotate(-45deg);
      transform-origin: right bottom;
    }

    &::after {
      transform: rotate(45deg);
      transform-origin: left bottom;
    }

    &:hover {
      border-color: $color-border-hover;

      &::before,
      &::after {
        margin-top: -4px;
      }
    }
  }

  + .c-button {
    margin-top: 20px;

    @media #{$mq-sm} {
      margin-top: 0;
      margin-left: 40px;
    }
  }
}

.c-more-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 260px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 16px 8px;
  text-align: center;
  background: $color-button;
  font-weight: 700;
  transition: all $duration;
  appearance: none;
  cursor: pointer;

  @media #{$mq-sm} {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    background-color: $color-button-hover;
  }

  &:not(:first-child) {
    margin-top: 30px;

    @media #{$mq-sm} {
      margin-top: 40px;
    }
  }

  &.c-more-button--white {
    background-color: #fff;

    &:hover {
      background-color: $color-base-xxlight;
    }

    .c-more-button__more {
      color: $color-base-light;

      &::after {
        background-color: $color-base-light;
      }
    }

    .c-more-button__label {
      color: $color-base;
    }
  }

  &__more {
    position: relative;
    display: inline-block;
    line-height: 1;
    padding-right: 1em;
    @extend %font-strong;
    @include fz(11);
    color: $color-base-xlight;
    transition: color $duration;

    &::after {
      content: '';
      opacity: .7;
      position: absolute;
      bottom: 50%;
      left: 100%;
      display: inline-block;
      width: 40px;
      height: 1px;
      background: $color-base-xlight;
      transform: translateY(-1px);
      transform-origin: left center;
      transition: transform $duration;

      .c-more-button:hover & {
        transform: scaleX(1.25) translateY(-1px);
      }
    }
  }

  &__label {
    display: inline-block;
    line-height: 1.5;
    margin-top: .5em;
    @include fz(15);
    color: #fff;
    letter-spacing: .16em;
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  // display: block;
  margin-left: auto;
  margin-right: auto;
}
