// =====================================
// Media
// =====================================
.u-show-sm {

  @media #{$mq-sm} {
    display: none !important;
  }
}

.u-show-md {

  @media #{$mq-md} {
    display: none !important;
  }
}

.u-show-lg {

  @media #{$mq-lg} {
    display: none !important;
  }
}

.u-hide-sm {

  @media #{$mq-not-sm} {
    display: none !important;
  }
}

.u-hide-md {

  @media #{$mq-not-md} {
    display: none !important;
  }
}
